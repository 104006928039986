<template>
    <Disclosure as="nav" class="bg-white shadow" v-slot="{ open }">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 justify-between">
                <div class="flex">
                    <NuxtLink to="/">
                        <div class="flex flex-shrink-0 items-center">
                            <img class="h-12 w-auto mt-2" src="~/assets/logo192.png"
                                alt="Your Company" />
                        </div>
                    </NuxtLink>
                    <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
                        <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->

                        <NuxtLink v-for="item in items" :to="item.href"
                            class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm text-gray-600 font-medium">
                            {{ item.name }}
                        </NuxtLink>
                    </div>
                </div>
                <div class="-mr-2 flex items-center sm:hidden">
                    <!-- Mobile menu button -->
                    <DisclosureButton
                        class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset">
                        <span class="absolute -inset-0.5" />
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                        <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                    </DisclosureButton>
                </div>
            </div>
        </div>

        <DisclosurePanel class="sm:hidden">
            <div class="space-y-1 pb-3 pt-2">
                <DisclosureButton as="a" href="/"
                    class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium text-gray-500">Home</DisclosureButton>
                <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
                <DisclosureButton v-for="item in items" as="a" :href="item.href"
                    class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium text-gray-500">
                    {{ item.name }}</DisclosureButton>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>
  
<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'


const items = [
    { name: "Home Owners", href: "/benefits/homeowners" },
    { name: "Service Providers", href: "/benefits/service-providers" },
    { name: "Gig Workers", href: "/benefits/gig-workers" },
    { name: "Investors", href: "/investors" }

]

</script>

<style scoped>
.router-link-active {
    @apply text-gray-800 border-sky-500;
}
</style>